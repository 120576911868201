<template>
  <div class="solutionCount">
    <div class="banner">
      <img src="@/assets/solutionCount/banner.png" alt="" />
    </div>
    <div class="title">了解典型解决方案</div>

    <div class="solution">
      <div class="solution_content">
        <div
          @mouseenter="change(item, $event)"
          @mouseleave="noChange(item, $event)"
          @click="toPath(item)"
          v-for="(item, index) in ImgList"
          :key="item.solutionId"
        >
          <img v-if="item.flag" :src="item.icon" alt="" class="noHover" />
          <img
            v-else
            :src="item.image"
            alt=""
            class="hover"
            :style="
              index === 2
                ? 'height: 588px;margin-top: -40px;'
                : index === 3
                ? 'height: 592px;margin-top: -42px;'
                : ''
            "
          />
        </div>
      </div>
    </div>
    <div class="title">合作院校展示</div>
    <div class="cooperation">
      <ul>
        <li v-for="item in cooperationList" :key="item.partnerSchoolsId">
          <img :src="item.image" alt="" />
        </li>
      </ul>
    </div>
    <div class="video_show">
      <img
        src="@/assets/solutionCount/video_show.png"
        alt=""
        @click="goVideo"
      />
    </div>
    <el-dialog :visible.sync="dialogVisible" width="375px" center>
      <div>
        <h1 class="login_title">用户登录</h1>
        <el-form ref="form" :model="login" :rules="rules">
          <el-form-item prop="username">
            <el-input
              v-model="login.username"
              placeholder="请输入用户名"
              prefix-icon="el-icon-user-solid"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="login.password"
              placeholder="请输入密码"
              type="password"
              prefix-icon="el-icon-lock"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="submit">登录</el-button>
      </div>
      <div slot="footer"></div>
    </el-dialog>
  </div>
</template>

<script>
import { solutionInfo, login } from "@/api/web";

export default {
  name: "SolutionCount",
  data() {
    return {
      ImgList: [],
      cooperationList: [],
      dialogVisible: false,
      login: {
        username: null,
        password: null,
      },
      rules: {
        username: [
          { required: true, tigger: "blur", message: "用户名不能为空" },
        ],
        password: [{ required: true, tigger: "blur", message: "密码不能为空" }],
      },
    };
  },
  created() {
    this.getSolutionInfo();
  },
  methods: {
    async getSolutionInfo() {
      const { data } = await solutionInfo();
      this.ImgList = data.solution;
      this.ImgList[0].flag = false;
      this.cooperationList = data.partnerSchools;
    },
    change(item) {
      this.ImgList.forEach((list) => {
        list.flag = true;
      });
      this.ImgList.forEach((list) => {
        if (list.solutionId === item.solutionId) {
          item.flag = false;
        }
      });
      // e.target.style = "width:549px";
    },
    noChange(item) {
      this.ImgList.forEach((list) => {
        if (list.solutionId === item.solutionId) {
          item.flag = true;
        }
      });
      // e.target.style = "width:184px";
    },
    toPath(item) {
      this.$router.push(`/solutionInside/${item.solutionId}`);
    },
    goVideo() {
      if (window.localStorage.getItem("zfkj_clientToken")) {
        this.$router.push("/video");
      } else {
        this.dialogVisible = true;
      }
    },
    submit() {
      this.$refs["form"].validate(async (val) => {
        if (val) {
          const { data } = await login(this.login);
          window.localStorage.setItem("zfkj_clientToken", data);
          this.$message.success("登录成功");
          this.dialogVisible = false;
          this.login = {
            username: null,
            password: null,
          };
          this.$router.push("/video");
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.solutionCount {
  .title {
    width: 1460px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 87px;
    font-size: 33px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #181818;
  }
  // 了解典型解决方案
  .solution {
    height: 548px;
    margin: 0 auto;
    background: #e5e5e5;

    .solution_content {
      display: flex;
      justify-content: space-between;
      width: 1460px;
      margin: 0 auto;
      cursor: pointer;
      div {
        height: 100%;
        .noHover {
          width: 182px;
          height: 550px;
          margin-top: -1px;
          transition: width 0.5s;
        }
        .hover {
          margin-top: -37px;
          height: 585px;
          width: 732px;
          transition: width 0.5s;
        }
      }
    }
  }
  // 合作院校展示
  .cooperation {
    width: 1460px;
    margin: 0 auto;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        height: 207px;
        background: #ffffff;
        border: 1px solid #d1d1d1;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
  // 视频展示
  .video_show {
    margin-top: 50px;
    margin-bottom: -5px;
    cursor: pointer;
  }
}
.login_title {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 25px;
  color: #181818;
}
::v-deep {
  .el-dialog {
    border-radius: 15px;
  }
  .el-dialog__body {
    padding: 25px 55px 30px;
  }
  .el-button {
    width: 100%;
    margin-top: 10px;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: #409eff;
    border-color: #409eff;
    color: #fff;
  }
}
</style>
